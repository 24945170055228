import { ResmiApi } from '@resmi/api';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: `${ResmiApi.dev.url}`,
  apiKey: `${ResmiApi.dev.key}`,
  apiSecret: `${ResmiApi.dev.secret}`,
  primaryDomain: `http://localhost:4200`,
  client: {
    primaryDomain: `https://esmi.kaiote.io`,
  },
  auth0: {
    clientId: '7SS9U8KHjuKbreXtocyeRv3I4WglySYQ',
    domain: 'dev-rbpb4bmc.au.auth0.com'
  },
  geojson: {
    boundaryField: 'ADMIN_BOUNDARY'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
