import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'analytics',
    loadChildren: () =>
      import('@resmi/page-modules/map-analytics/map-analytics.module').then(
        m => m.MapAnalyticsModule
      )
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@resmi/page-modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@resmi/page-modules/admin-home/admin-home.module').then(
        m => m.AdminHomeModule
      )
  },
  {
    path: 'downloads',
    loadChildren: () =>
      import('./@resmi/page-modules/downloads/downloads.module').then(
        m => m.DownloadsModule
      )
  },
  {
    path: 'landing-page-legacy',
    loadChildren: () =>
      import('@resmi/page-modules/landing-page/landing-page.module').then(
        m => m.LandingPageModule
      )
  },
  {
    path: '',
    loadChildren: () =>
      import(
        './@resmi/page-modules/landing-page-next/landing-page-next.module'
      ).then(m => m.LandingPageNextModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
