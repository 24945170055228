export const ResmiApi = {
  dev: {
    url: 'https://resmi.kaiote.io',
    key: '78cb37b0-95a6-49ce-b544-5d83f90add0f',
    secret: '6a9bc283-366b-4a65-aa0d-b79c777b1d26'
  },
  prod: {
    url: 'https://resmi.kaiote.io',
    key: '78cb37b0-95a6-49ce-b544-5d83f90add0f',
    secret: '6a9bc283-366b-4a65-aa0d-b79c777b1d26'
  }
};
