import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '@resmi/services/utils/utils.service';

import { ITheme } from '@resmi/interfaces/theme/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public themes: ITheme[] = [
    { name: 'Amber', className: 'resmi-amber' },
    { name: 'Blue', className: 'resmi-blue' },
    { name: 'Blue-grey', className: 'resmi-blue-grey' },
    { name: 'Brown', className: 'resmi-brown' },
    { name: 'Cyan', className: 'resmi-cyan' },
    { name: 'Deep-orange', className: 'resmi-deep-orange' },
    { name: 'Deep-purple', className: 'resmi-deep-purple' },
    { name: 'Default', className: 'resmi-default' },
    { name: 'Green', className: 'resmi-green' },
    { name: 'Indigo', className: 'resmi-indigo' },
    { name: 'Light-blue', className: 'resmi-light-blue' },
    { name: 'Light-green', className: 'resmi-light-green' },
    { name: 'Orange', className: 'resmi-orange' },
    { name: 'Pink', className: 'resmi-pink' },
    { name: 'Purple', className: 'resmi-purple' },
    { name: 'Red', className: 'resmi-red' },
    { name: 'Teal', className: 'resmi-teal' },
    { name: 'Yellow', className: 'resmi-yellow' },
  ];
  public defaultTheme: ITheme = this.themes[7];
  public isDarkMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public activeTheme: BehaviorSubject<ITheme> = new BehaviorSubject(this.defaultTheme);
  constructor(
  private utilSvc: UtilsService
  ) { }

  updateTheme(newTheme: ITheme): void {
    if (newTheme && newTheme.className) {
    this.activeTheme.next(newTheme);
    }
  }

  triggerDarkTheme(): void {
    this.isDarkMode.next(true);
    this.updateTheme({ name: 'Dark', className: 'resmi-dark' });
  }

  cancelDarkTheme(): void {
    this.isDarkMode.next(false);
    this.updateTheme(this.defaultTheme);
  }

  getCurrentTheme(): Observable<ITheme> {
    return this.activeTheme.asObservable();
  }

  getRandomTheme(): ITheme {
    const randomNumber = this.utilSvc.getRandomInteger(0, this.themes.length - 1);
    return this.themes[randomNumber];
  }

}
