import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  ITranslationLanguage,
  IPageTranslations,
} from '@resmi/interfaces/translation/translation';

@Injectable({
  providedIn: 'root',
})
export class TranslationControllerService {
  public activeLanguageKey = 'activeLanguage';
  public activeTranslations: ITranslationLanguage[] = [
    { name: 'English', id: 0, code: 'en_Ke', active: true },
    { name: 'French', id: 1, code: 'fr', active: false },
  ];
  private pageTranslations: IPageTranslations;
  public translationStream: BehaviorSubject<
    ITranslationLanguage
  > = new BehaviorSubject(this.activeTranslations[0]);
  constructor() {}

  public updateCurrentLanguage(activeLanguageIndex: number): void {
    this.activeTranslations.forEach((item) => {
      item.active =
        item && item.id && item.id === activeLanguageIndex ? true : false;
    });
    const activeLanguage = this.activeTranslations.filter(
      (item) => item && item.active === true
    )[0];
    if (activeLanguage) {
      this.translationStream.next(activeLanguage);
      localStorage.setItem(
        this.activeLanguageKey,
        JSON.stringify(activeLanguage.id)
      );
    }
  }

  public updateTextTranslations(translations: any): any {
    if (typeof translations !== 'undefined') {
      this.pageTranslations = Object.assign({}, translations);
      return translations;
    }
  }

  public getPageTranlations(): IPageTranslations {
    return Object.assign({}, this.pageTranslations);
  }
}
