/* Angular Imports */
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

export type snackbarType = 'success' | 'info' | 'error' | 'warning';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snack: MatSnackBar) {}

  /**
   * Show Message
   * @param message string
   * @param [messageType] snackbarType
   * @param [action] null | string
   * @param [duration] number
   * @returns MatSnackBarRef<SimpleSnackBar>
   */
  public show(
    message: string,
    messageType: snackbarType = null,
    action = null,
    duration = 3500
  ): MatSnackBarRef<SimpleSnackBar> {
    if (message && typeof message === 'string') {
      const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snack.open(
        message,
        action,
        {
          duration,
          panelClass: messageType ? `resmi-snackbar-${messageType}` : undefined
        }
      );
      return snackBarRef;
    }
  }
}
