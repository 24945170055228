import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { SnackbarService } from '@resmi/services/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAuthInterceptorService implements HttpInterceptor {
  constructor(
    private snackbarSvc: SnackbarService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
          this.snackbarSvc.show(`${error.error.error} - ${error.error.message}`, 'warning', 'OK', 30000);
        }
        throw new Error(errorMessage);
      })
    );
  }
}
