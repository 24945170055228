import { Injectable } from '@angular/core';
import { random } from 'lodash-es';

import { morphArrAction, MorphArrActionResult } from '@resmi/interfaces/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {}

  /**
   * Gets random integer
   * @param [start] number
   * @param end number
   * @returns random integer number
   */
  public getRandomInteger(start: number = 0, end: number): number {
    if (typeof start !== 'undefined' && typeof end !== 'undefined') {
      return random(start, end);
    } else {
      return random();
    }
  }

  public morphArrayObjects(oldArr: any[], morphedObj: any, action: morphArrAction): MorphArrActionResult {
    if (action === 'ADD') {
    return this.morphAddInArray(oldArr, morphedObj);
    } else if (action === 'UPDATE') {
    return this.morphUpdateInArray(oldArr, morphedObj);
    } else if (action === 'REMOVE') {
    return this.morphRemoveFromArray(oldArr, morphedObj);
    } else {
      throw new Error('Unsupported List Action');
    }
  }

  private morphAddInArray(oldArr: any[], newObj: any): MorphArrActionResult {
    if (this.confirmValidityBeforeArrayMorph(oldArr, newObj)) {
      const newArr = [...oldArr, newObj];
      return { newArr, oldArr, newObj, objId: newObj.id, index: newArr.length - 1, success: true };
    } else {
      return { oldArr : oldArr && oldArr.length ? oldArr : [], newObj, success: false, error: new Error('Null/Undefined Parameters')};
    }
  }

  private morphUpdateInArray(oldArr: any[], newObj: any): MorphArrActionResult {
    if (this.confirmValidityBeforeArrayMorph(oldArr, newObj)) {
      const newObjId = newObj.id;
      const newArr = [ ...oldArr ];
      const objIndex = oldArr.findIndex(o => o && o.id && o.id === newObjId);
      if (objIndex !== -1) {
        newArr[objIndex] = newObj;
      }
      return { newArr, oldArr, newObj, objId: newObj.id, index: objIndex, success: true };
    } else {
      return { oldArr : oldArr && oldArr.length ? oldArr : [], newObj, success: false, error: new Error('Null/Undefined Parameters')};
    }
  }

  private morphRemoveFromArray(oldArr: any[], newObj: any): MorphArrActionResult {
    if (this.confirmValidityBeforeArrayMorph(oldArr, newObj)) {
      const newObjId = newObj.id;
      const newArr = [ ...oldArr ];
      const objIndex = newArr.findIndex(o => o && o.id && o.id === newObjId);
      if (objIndex !== -1) {
        newArr.splice(objIndex, 1);
      }
      return { newArr, oldArr, newObj, objId: newObj.id, index: objIndex, success: true };
    } else {
      return { oldArr : oldArr && oldArr.length ? oldArr : [], newObj, success: false, error: new Error('Null/Undefined Parameters')};
    }
  }

  private confirmValidityBeforeArrayMorph(oldArr: any[], newObj: any): boolean {
    return (
      newObj && newObj.id && oldArr && oldArr.length && Array.isArray(oldArr)
    );
  }
}
