import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { LoadableModule, matcher } from 'ngx-loadable';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { ApiAuthInterceptorService } from './@resmi/libs/interceptors/api-auth-interceptor/api-auth-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const MaterialModules = [
  MatSnackBarModule,
  MatProgressBarModule
];

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#43A047'
    }
  },
  theme: 'classic',
  position: 'bottom-right'
};

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatNativeDateModule,
    LoadableModule.forRoot({
      moduleConfigs: [
        {
          name: 'FiltersModule',
          loadChildren: () =>
            import('src/app/@resmi/libs/modules/filters/filters.module').then(
              m => m.FiltersModule
            ),
          matcher
        },
        {
          name: 'SidenavLayoutModule',
          loadChildren: () =>
            import(
              'src/app/@resmi/core/sidenav-layout/sidenav-layout.module'
            ).then(m => m.SidenavLayoutModule),
          matcher
        }
      ]
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ...MaterialModules
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
