<div role="main" class="{{ currentTheme?.className }}">
  <!-- START PROGRESS BAR -->
  <mat-progress-bar mode="indeterminate" class="progress-bar" *ngIf="loadingComponent"></mat-progress-bar>
  <!-- END PROGRESS BAR -->

  <ngx-loadable module="SidenavLayoutModule" [show]="true">

    <ng-template #loading>Loading...</ng-template>
    <ng-template #error>Loading failed...</ng-template>

  </ngx-loadable>
</div>
