import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Router,
  RouteConfigLoadEnd,
  RouteConfigLoadStart
} from '@angular/router';

import { Subscription } from 'rxjs';

import { ThemeService } from '@resmi/services/theme/theme.service';
import { TranslationControllerService } from '@resmi/services/translation-controller/translation-controller.service';

import { ITheme } from '@resmi/interfaces/theme/theme';
import { IPageTranslations } from '@resmi/interfaces/translation/translation';

@Component({
  selector: 'resmi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Resmi';
  public loadingComponent = false;
  public routeSubscription: Subscription;
  public themeSubscription: Subscription;
  public currentTheme: ITheme;
  constructor(
    private router: Router,
    private themeSvc: ThemeService,
    private overlayContainer: OverlayContainer,
    private http: HttpClient,
    private translationsSvc: TranslationControllerService
  ) {}

  listenOnComponent() {
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        setTimeout(() => {
          this.loadingComponent = true;
        });
      } else if (event instanceof RouteConfigLoadEnd) {
        setTimeout(() => {
          this.loadingComponent = false;
        });
      }
    });

    this.themeSubscription = this.themeSvc
      .getCurrentTheme()
      .subscribe((updatedTheme: ITheme) => {
        const updatedClassName =
          updatedTheme && updatedTheme.className
            ? updatedTheme.className
            : undefined;
        const currentClassName =
          this.currentTheme && this.currentTheme.className
            ? this.currentTheme.className
            : undefined;
        this.updateOverlayTheme(updatedClassName, currentClassName);
        this.currentTheme = updatedTheme;
      });
  }

  /**
   * Updates overlay theme
   * @param newTheme The new theme for update change
   * @param [oldTheme] The old theme to update
   * @return void
   */
  updateOverlayTheme(newTheme: string, oldTheme?: string): void {
    if (oldTheme) {
      this.overlayContainer.getContainerElement().classList.remove(oldTheme);
    }
    if (newTheme) {
      this.overlayContainer.getContainerElement().classList.add(newTheme);
    }
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.listenOnComponent();
    this.http.get('assets/translations/main.json').subscribe((response: IPageTranslations) => {
    console.log(response);
    this.translationsSvc.updateTextTranslations(response);
    const activeLanguageIndex = JSON.parse(localStorage.getItem(this.translationsSvc.activeLanguageKey));
    if (typeof(activeLanguageIndex) !== 'undefined') {
      this.translationsSvc.updateCurrentLanguage(activeLanguageIndex);
    }
    }, e => {
      console.error(e);
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.routeSubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
  }
}
